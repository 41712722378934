@media all and (min-width:801px) {
  #section4 .layer .body .videoSlideTray {width:100%!important;}
}
@media all and (max-width:800px) {
  
/* Modal Content */
.modal-content {
  position: relative;
  background:#020916;
  border:1px solid #7565ac;
  max-width:680px;
  max-height:100vh;
  top:50%;
  left:0;
  transform: translateY(-50%);
  margin: auto;
  padding: 0;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Modal Header */
.modal-content {max-height:80vh;}
.modal-content .modal-header {
  width:100%;
  box-sizing: border-box;
  text-align:right;
}

/* Modal Body */
.modal-content .modal-body {padding: 0 (34/ 900) * 100vw; text-align:center;}
.modal-content .modal-body:not(.reserveComplete) {padding: 0 (48/ 900) * 100vw (30/ 900) * 100vw; text-align:center;}
/* Modal Footer */
.modal-content .modal-footer {
  width:100%;
  box-sizing: border-box;
  padding: (35/900)*100vw 0;
  text-align:center;
}

/* The Close Button */
.modal-content .modal-close {
  border:0;
  outline:none;
  margin:0 auto;
  font-size: 0;
  position: relative;
  width:(170 / 900) * 100vw;
}

.modal-content .modal-close:hover,
.modal-content .modal-close:focus {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.modal-content .close-mark {
  position:relative;
  background:transparent;
  border:0;
  outline:none;
  text-align:center;
  width:(59 / 900) *100vw; height:(59 / 900) *100vw;
  display:inline-block;
  // top:-2.3046875vw; right:0;
  right: 0; top: -(75 / 900) * 100vw;
  cursor:pointer;
  padding:0;
}

.modal-content .close-mark:hover,
.modal-content .close-mark:focus {
  outline:none;
}

.modal-content .close-mark:before,
.modal-content .close-mark:after {
  content:'';
  display:block;
  position:absolute;
  top:50%; left:0;
  opacity: 1;
  transition: .4s ease-out;
  height:1px;
  width:100%;
  background-color:#7565ac;
  transform:rotate(45deg);
  transform-origin:50% 50%;
}
.modal-content .close-mark:after {
  transform: rotate(-45deg);
}

.modal-content .close-mark span {display:none;}

.modal-content .pop_title {font-size:(33 / 900) * 100vw; font-weight:700; color:#8167d5; margin-bottom:(40 / 900) * 100vw;}
.modal-content .pop_title.type2 { font-size:(26 / 900) * 100vw; font-weight:700; color:#8167d5; margin-bottom:(40 / 900) * 100vw; line-height:(34 / 900) * 100vw; letter-spacing: (-1 / 900) * 100vw}

.modal-content .pop_desc,
.modal-content .pop_desc * {font-size:(17 / 900) * 100vw; font-weight:700; color:#66598f; line-height:(24 / 900) * 100vw;}
.modal-content .pop_desc {max-height:50vh;}
.modal-content .pop_desc li {margin-bottom:(17 / 900) * 100vw}
  

input[type=radio]~.checkText {
  font-size: (40/900)*100vw;
}

input[type=checkbox]~.checkText {
  font-size: (23/900)*100vw;
  line-height: (32/23)*100%;
}

input[type=radio]+.checkIcon,
input[type=checkbox]+.checkIcon {
  width: (26 /900)*100vw;
  height: (26 /900)*100vw;
  border: (7/900)*100vw solid #6f86cf;
  border-radius: (7/900)*100vw;
}

input[type=radio]:checked+.checkIcon,
input[type=checkbox]:checked+.checkIcon {
  width: (24 /900)*100vw;
  height: (24 /900)*100vw;
  border: (8 /900)*100vw solid #c9afff;
}

input[type=radio]:checked+.checkIcon:after,
input[type=checkbox]:checked+.checkIcon:after {
  content: '';
  width: (26 /900)*100vw;
  height: (26 /900)*100vw;
  border-radius: (7 /900)*100vw;
}

::-webkit-input-placeholder {
  font-size: (30/900)*100vw;
}

:-ms-input-placeholder {
  font-size: (30/900)*100vw;
}

::-ms-input-placeholder {
  font-size: (30/900)*100vw;
}

::placeholder {
  font-size: (30/900)*100vw;
}

#section1 {
  .fp-tableCell {
    background: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/registration/reservation_background.png);
    background-size: 100% 100%;

    .layer {
      position: relative;
      height: 100%;

      .title {
        position: absolute;
        top: 0;
        left: 50%;
        width: 800px;
        background-size: (2000/900)*100% (1024 / 1280)*100%;
        background-position: center top;
        background-repeat: no-repeat;
        transform: translate(-50%, -100%);
        background-image: url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/registration/reservation_curtain.png");
        height: 72.85714285714286vh;
        width: 100%;

        img {
          width: (800 / 900) * 100vw;
          max-width: 800px;
          margin-top: 3.571428571428571vh;
        }
      }

      .body {
        position: absolute;
        top: 19.5%;
        bottom: 0;
        width: (800 / 900) * 100vw;
        max-width: 800px;
        left: 0;
        right: 0;
        margin: auto;

        .reward {
          margin-bottom: 1.142857142857143vh;

        }

        .reserve_form {
          margin-bottom: 12px;

          .step1 {
            background: #f1efff;

            .selectCountry {
              width: 100%;
              padding: (23/1280)*100vh (70/900)*100vw;
              border-bottom: 1px solid #a99bff;

              ul {
                &:after {
                  content: '';
                  display: block;
                  clear: both;
                }

                li {
                  float: left;
                  width: 33.33%;
                  box-sizing: border-box;
                  text-align: center;
                }
              }
            }

            .selectTel {
              padding: 0 (70/900)*100vw;

              input {
                background: transparent;
                font-size: (30/900)*100vw;
                height: (75/900)*100vw;
                line-height: (75/900)*100vw;
                text-align: center;
              }
            }
          }

          .step2 {
            background: #37355b;
            padding: (18 / 900) * 100vw 0;
            text-align: center;
            box-sizing: border-box;

            button {
              margin-left: (105/900)*100vw;
              width: (140/900)*100vw;
            }
          }

          .step3 {
            .reserveBtn {
              width: 100%;
              position: relative;
            }
          }
        }
      }

      .warning {
        text-align: left;
        height: (60 / 900) * 100vw;
      }

      .warning img {
        height: 100%;
      }
    }
  }

  &.active {
    .fp-tableCell {
      .layer {
        .title {
          transition: 700ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
          transform: translate(-50%, 0);
          transition-delay: 100ms;
        }
      }
    }
  }

}


}
@media all and (max-width:800px) and (orientation: portrait) {
 
.modal-video-close-btn {
  position: absolute;
  background: transparent;
  border: 0;
  outline: none;
  text-align: center;
  width: (59 / 900) * 100vw;
  height: (59 / 900) * 100vw;
  display: inline-block;
  top: -(59 / 900) * 100vw;
  right: 0;
  cursor: pointer;
  padding: 0;
}

  #wrapper {
    .menu-trigger {
      border:(100 / 900) * 100vw solid #413c86;
      
      a {
        position:absolute;
        display: block;
        height: 3px;
        width: (62 / 900) * 100vw;
        background-color:#bab6e9;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        top:(-40 / 900) * 100vw;;
        left:(-70 / 900) * 100vw;
  
        &:before,
        &:after {
          content:'';
          position:absolute;
          display: block;
          height: 3px;
          width:(62 / 900) * 100vw;
          background-color:#bab6e9;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          top:(-14 / 900) * 100vw;
        }
        &:after {top:(14 / 900) * 100vw;}
      }
    }

    #header {
      .menu-wrapper {
        width: (500 / 900) * 100vw;
        max-width:500px;
          
        .menu-inner {
          background-color: rgba(33, 33, 44, 0.9);
          height: 100%;
          left: 0;
          width: 100%;
          padding: 22%; padding-top:13.6%;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          transition: all .2s ease;
          position: absolute;
          
          .logo {
            img {width:100%;}
          }
          .gotop {
            display:block; width:(125 / 900) * 100vw; margin:(90 / 1280) * 100vh auto; text-align: center;
          }
  
          .menu {
            margin:(60 / 1280) * 100vh auto; width:(288 / 500) * 100%;
          
            li {
              margin: (45 / 1280) * 100vh 0;
              text-align: center;
  
              a {
                position:relative;
                display: block;
                margin:0 auto;
              }
  
              &.active a:after {opacity: 1;}
  
              &[data-menuanchor="reservation"].active a:after {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/gnb_button_list1-on.png");}
              &[data-menuanchor="newStory"].active a:after {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/gnb_button_list1-on.png");}
              &[data-menuanchor="newCharacter"].active a:after {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/gnb_button_list1-on.png");}

              &:nth-child(4) a,
              &:nth-child(5) a {
                width: (93 / 900) * 100vw;
              }
            }
          }
  
          .link {
            position:absolute;height: 5.2vw; bottom: 4.6875vw; margin-bottom:40px;
            left:0; right:0;
            width:63%; margin:0 auto;
  
            &:after {content:''; display:block; clear:both;}
  
            li {
              float:left;
              height:100%;
              width:33.3%;
              text-align:center;
  
              &:last-child {
                float:right;
              }
  
              a {
                display:inline-block;
                position:relative;
                overflow:hidden;
                height:100%;
  
                img {height:100%;}
              }
            }
          }
        }
      }	
    }

    .scroll-icon {
      position: absolute;
      padding: 0;
      width: 100%;
      bottom: 1.5875vw;
      z-index: 50;
      padding: 0 10px;

      p {
        font-size: 0;
        color: transparent;
        height: (12 / 900) * 100vw;
        width: (54 / 900) * 100vw;
        text-align: center;
        text-indent: -9999px;
        background: url(https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/allSection_text__scroll.png) 0 0 no-repeat;
        background-size: 100% 100%;
        z-index: 3;
        margin: 0 auto;
      }

      i {
        display: block;
        width: 2px;
        height: 2.5vh;
        margin: 0.8298755186721992vh auto;
        position: relative;
        overflow: hidden;
        background-color: #666692;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -2.5vh;
          height: 2.5vh;
          width: 2px;
          background-color: #fff;
          -webkit-animation: nextScrollAni 3s 2s linear infinite;
          animation: nextScrollAni 3s 2s linear infinite;
        }
      }
    }

    #main {
      .layer {
        height:100%; position:relative;
        
        .layer-inner {
          .modal-vid {
            margin:0 auto; width:(82 / 900) * 100vw; height:(82 / 900) * 100vw;
    
            .modal-vid-inner {
              width:(54 / 900) * 100vw; height:(54 / 900) * 100vw;
    
              button {width:(37 / 900) * 100vw; height:(37 / 900) * 100vw;}
            }
          }
          
          .modal-vid {
            transform:rotate(135deg);
          }
          .modal-vid-inner {
            transform:rotate(90deg);
          }
          .modal-vid:before,
          .modal-vid:after,
          .modal-vid .modal-vid-inner:before,
          .modal-vid .modal-vid-inner:after {
            animation: snake2 4s linear infinite;
          }
          .modal-vid:after,
          .modal-vid .modal-vid-inner:after {
            transform:rotate(180deg);
            animation-delay: 4s;
          }
    
          .content {
            position:relative; width: (800 / 900) * 100vw; max-width:800px; margin: 0 auto;
    
            h1 {
              position:relative;
              margin-top:12px;
              
              &:before {
                content: ''; position: absolute; display: block; background: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mainSection_text__headtext-glow.png) center center no-repeat; background-size: 100% 100%; width: 25.0375vw; height: 11.516667vw; top: 9.4%; right: 6.858333%;
              }
              
              &:after {
                content: ''; position: absolute; display: block; background: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mainSection_text__headtext-glow.png) center center no-repeat; background-size: 100% 100%; width: 25.0375vw; height: 11.516667vw; top: 9.4%; right: 6.858333%; animation: glow 1.7s infinite alternate forwards; opacity: 0;
              }
            }      
          }
        }
    
        #newsfeed {display:none;}     
      }
    }

    

    #section2 {
      .slide {
        .bg {position:absolute; width:100vw; height:100vh; top:0; left:0; z-index:-1; background-position: center center; background-size: cover; resize: both;
          
          &:after {
            content:'';
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%,-100%);
            -ms-transform: translate(-50%,-100%);
            transform: translate(-50%,-100%);
            background-image: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_curtain.png);
            background-size:(1548/900)*100% (453 / 1280)*100%;
          }
        }
        .layer {
          position:relative; height:100%;
          .obj {
            position:absolute; opacity: 0;
            
            img {width:100%;}
          }
    
          .body {
            position:absolute; top:0; bottom:0; width: 100vw; max-width:800px; left:0; right:0; margin:auto;
    
            .txt {z-index:5; position:absolute; width:100%; height:100%; top:0;left:0; width:100%;
              p {position:absolute; left:0; opacity: 0;}
            }
          }
        }
        
        &#slide2-1 {
          .bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page1_background.jpg");}
          .obj {
            margin-top: (100 / 1280) * 100vh;
            &.obj1 {
              width:(1912 / 1280) * 100vw;
              height:auto;
              top:0;
              left:0;
              transform:translateY(-3%);
              z-index: 5;
            }
            &.obj2 {
              width: (507 / 1280) * 100vw;
              top: (453 / 1280) * 100vw;
              left: (517 / 1280) * 100vw;
              transform:translate(-15%, 12%);
              z-index: 2;
            }
            &.obj3 {
              width: (783 / 1280) * 100vw;
              top: (378 / 1280) * 100vw;
              left: (1355 / 1280) * 100vw;
              transform:translate(-15%, 8%);
              z-index: 3;
            }
            &.obj4 {
              width: (742 / 1280) * 100vw;
              top: (448 / 1280) * 100vw;
              left: (128 / 1280) * 100vw;
              transform:translate(5%, 10%);
              z-index: 4;
            }
            &.obj5 {
              width: (479 / 1280) * 100vw;
              top: (328 / 1280) * 100vw;
              left: (1761 / 1280) * 100vw;
              transform:translate(-15%, 3%);
              z-index: 1;
            }
          }
          .txt {
            p {
              position:absolute; left:0; opacity: 0;
  
              &.title {top:(828/1280)*100%;}
              &.subtitle {top:(918/1280)*100%;}
              &.description {top:(963/1280)*100%;}
  
            }
          }
        }
        &#slide2-2 {
          .bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page2_background.jpg");}
          .obj {
            margin-top: (100 / 1280) * 100vh;
            &.obj1 {
              width:(966 / 1280) * 100vw;
              height:auto;
              top: (228 / 1280) * 100vw;
              left: (1201 / 1280) * 100vw;
              transform:translateX(-3%);
              z-index: 1;
            }
            &.obj2 {
              width: (713 / 1280) * 100vw;
              top: (72 / 1280) * 100vw;
              left: (630 / 1280) * 100vw;
              transform:translate(-8%, 5%);
              z-index: 2;
            }
            &.obj3 {
              width: (1197 / 1280) * 100vw;
              top: (167 / 1280) * 100vw;
              left: (609 / 1280) * 100vw;
              transform:translateY(8%);
              z-index: 3;
            }
          }
          .txt {
            p {
              position:absolute; left:0; opacity: 0;
  
              &.title {top:(808/1280)*100%;}
              &.subtitle {top:(898/1280)*100%;}
              &.description {top:(963/1280)*100%;}
  
            }
          }
        }
        &#slide2-3 {
          .bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page2_background.jpg");}
          .obj {
            margin-top: (100 / 1280) * 100vh;
            &.obj1 {
              width:(1501 / 1280) * 100vw;
              top: 0;
              left: (567 / 1280) * 100vw;
              transform:translateY(0);
              animation:bounce 3s infinite alternate-reverse;
              z-index: 5;
            }
            &.obj2 {
              width: (885 / 1280) * 100vw;
              top: 0;
              left: (1079 / 1280) * 100vw;
              animation:glow 6s infinite alternate-reverse;
              z-index: 2;
            }
          }
          .txt {
            p {
              position:absolute; left:0; opacity: 0;
  
              &.title {top:(828/1280)*100%;}
              &.subtitle {top:(918/1280)*100%;}
              &.description {top:(963/1280)*100%;}
  
            }
          }
        }
        &#slide2-4 {
          .bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page2_background.jpg");}
          .char {
            position:absolute; top:0; bottom:0; left:0; right:0; margin:auto;
          
            > img {width:100%; height:auto;position:absolute; top:0; bottom:0; left:0; right:0; margin:auto;}
    
            &.char1 {width:90%;height:90%; transform:scale(1.2); transition:none; z-index: 5; transform-origin: 50% 50%;}
            &.char2 {width: 87%;height:90%; transform:translateX(0); transition:none; }
            &.char3 {width: 61.020833%;height:90%;transform:translateX(0); transition:none; }
          }
          .txt {
            p {
              position:absolute; left:0; opacity: 0;
  
              &.title {top:(808/1280)*100%;}
              &.subtitle {top:(898/1280)*100%;}
              &.description {top:(963/1280)*100%;}
              &.script {display:none;}
            }
          }
          &.active {
            .fp-tableCell {
              .layer {
                .body {
                  .char1 {transform:scale(1);}
                  .char2 {transform: translateX(-30.965553%);}
                  .char3 {transform:translateX(62.599104%);}							
                }
              }
            }
          }
        }
      }

      .fp-slidesNav {
        width: 100%; text-align: center; top:(30 / 1280) * 100vh;
      
        ul {
          li {
            margin: 0 (10/900)*100vw; width:(124 / 900) * 100vw; height:(139 / 900) * 100vw;
          
            a {
              width:100%; height:100%; background-size: 100% 100%; background-repeat: no-repeat; background-position: 0 0;
    
              span {display:none;}
            }
            &:nth-child(1) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination1-off--m.png");}
            &:nth-child(2) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination2-off--m.png");}
            &:nth-child(3) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination3-off--m.png");}
            &:nth-child(4) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination4-off--m.png");}
            &:nth-child(1) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination1-on--m.png");}
            &:nth-child(2) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination2-on--m.png");}
            &:nth-child(3) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination3-on--m.png");}
            &:nth-child(4) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/story/story_button__pagination4-on--m.png");}
            
          }
        }
      }
      
      &.active {
        .fp-slide.active {
          .fp-tableCell {            
            .layer {
              .obj {
                opacity:1!important; transform:translate(0, 0)!important;transition: none !important; margin-left:-50%;
              }
            }
          }
        }
      }
      .fp-controlArrow {visibility: hidden;}
    }
  
    #section3 .slide .txt {
      z-index: 5;
      top: initial;
      bottom:0;
      left: 0;
      right:0;
      opacity: 1;
      transform: translateX(0) translateY(30%);
      transform-origin: center top;
      height: (338 / 1280) * 100%;
      width: 100%;
      background: #eeeeee;
      background-size: 100% 100%;
      transition: .2s ease-in;
    }
    #section3 .slide.active .txt {transform: translateX(0) translateY(0)!important; opacity: initial; top:auto;}

    #section3 .slide.isOpened .txt {
      transform: translateX(0) translateY(0);
      opacity: 1;
      height: (610 / 1280) *100%;
      transition: .5s ease-out;
    }

    #section3 .slide .txt .txt_inner {
      position: relative;
      top: 0;
      left:0;
      right: 0;
      box-sizing: border-box;
      margin-top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      margin-right: 0;
    }
    #section3 .slide .txt .txt_inner .charTitle {transform:translateY(15%); height:(92 / 1280) * 100vh;}
    #section3 .slide .txt.isOpened .txt_inner .charTitle {transform:translateY(0)}
    #section3 .slide .txt .txt_inner .charSubtitle {display:none; height:(40 / 1280) * 100vh;}
    #section3 .slide .txt .txt_inner .charInfo {display:none; height:(40 / 1280) * 100vh;}
    #section3 .slide .txt .txt_inner .charDescription {display:none; height:(204 / 1280) * 100vh;}
    #section3 .slide .txt .txt_inner .charMov {position:fixed; top:-30vh; left:50%; transform:translate(-50%, -50%); width:(163/900) * 100vw; height:(160/900) * 100vw;}
    #section3 .slide.isOpened .txt .txt_inner .charSubtitle {display:block; margin-top:(20 / 1280) * 100vh;}
    #section3 .slide.isOpened .txt .txt_inner .charInfo {display:block; margin-bottom:0;}
    #section3 .slide.isOpened .txt .txt_inner .charDescription {display:block;}
    #section3 .slide .img {
      position: absolute;
      z-index: 0;
      top: 0;
      left:0;
      right: 0;
      transform:translate(0%,0%);
      opacity: 1;
      transform-origin: center top;
      height: 100%;
      width: 100%;
    }
    #section3 .slide .img {transform: translateY(0); pointer-events: auto; /* position:relative; */}
    #section3 .slide.isOpened .img {
      opacity: 1;
      margin-right: 0;
      transition: 1.2s ease-out;
      transition-delay: 0s;
    }
    #section3 .slide .img img {height:auto; width: 125%; max-width: 125%; transform:translateX(-12.5%); position:initial; margin:initial;}
    #section3 .slide#slide1 .img {transform:translate(0%,-5.5%);
    }
    #section3 .slide#slide2 .img {transform:translate(0%,0%);
    }
    #section3 .slide#slide3 .img {transform:translate(9.5%,-1.5%);
    }
    #section3 .slide#slide4 .img {transform:translate(2.5%,-2.5%);
    }
    #section3.active .slide.active .img:after {display:block;}
    #section3 .slide.active#slide1 .img {transform:translate(0%,-8%);}
    #section3 .slide.active#slide2 .img {transform:translate(2.5%,0%);}
    #section3 .slide.active#slide3 .img {transform:translate(12%,-4%);}
    #section3 .slide.active#slide4 .img {transform:translate(5%,-5%);}
    #section3 .slide.active.isOpened#slide1 .img {transform:translate(0%,-18%);transition: .3s ease-out; transition-delay:.2s;}
    #section3 .slide.active.isOpened#slide2 .img {transform:translate(2.5%,-10%);transition: .3s ease-out; transition-delay:.2s;}
    #section3 .slide.active.isOpened#slide3 .img {transform:translate(12%,-14%);transition: .3s ease-out; transition-delay:.2s;}
    #section3 .slide.active.isOpened#slide4 .img {transform:translate(5%,-15%);transition: .3s ease-out; transition-delay:.2s;}

    #section3 .slide#slide1 .img img {transform:translate(-11.4%,-11.794398%);}
    #section3 .slide#slide2 .img img {transform:translate(-12%,1.381643%);}
    #section3 .slide#slide3 .img img {transform:translate(-11%,-8.458937%);}
    #section3 .slide#slide4 .img img {transform:translate(-12%,-8.941476%);}
    #section3 .slide .mToggle {
      position:absolute;
      top:0; right:0;
      height:0; width:0;
      border:7.552083333333333vw solid #c2c2c2;
      border-left:7.552083333333333vw solid transparent;
      border-bottom:7.552083333333333vw solid transparent;
      z-index: 100;
    }
    #section3 .slide .mToggle:before {
      position:absolute;
      content: ''; display:block;
      width:6.5625vw; height:2px;
      transform-origin: center center;
      top: -2.675vw;
      right: -6.375vw;
      background-color:#fff;
    }
    #section3 .slide .mToggle:after {
      position:absolute;
      content: ''; display:block;
      width:6.5625vw; height:2px;
      transform-origin: center center;
      transform:rotate(-90deg);
      top: -2.675vw;
      right: -6.375vw;
      background-color:#fff;
    }
    #section3 .slide.isOpened .mToggle {
      position:absolute;
      top:0; right:0;
      height:0; width:0;
      border:7.552083333333333vw solid transparent;
    }
    #section3 .slide.isOpened .mToggle:before {
      transform:rotate(-45deg);
      height:1px;
      background-color:#cfcfcf;
    }
    #section3 .slide.isOpened .mToggle:after {
      transform:rotate(-135deg);
    height:1px;
      background-color:#cfcfcf;
    }
    
    #section3 {
      .fp-slidesNav {
        width: 100%; text-align: center; transform:translateX(50%); top: auto; bottom:(40 / 1280) * 100vh;
      
        ul {
          margin-right:auto; width:100%;
        
          li {
            // margin:0 0.390625vw; width:2.890625vw; height:3.3984375vw;
            margin: 0 (10/900)*100vw; width:(133 / 900) * 100vw; height:(149 / 900) * 100vw;
          
            a {
              width:100%; height:100%; background-size: 100% 100%; background-repeat: no-repeat; background-position: 0 0;

              span {display:none;}
            }
            &:nth-child(1) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination1-off--m.png");}
            &:nth-child(2) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination2-off--m.png");}
            &:nth-child(3) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination3-off--m.png");}
            &:nth-child(4) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination4-off--m.png");}
            &:nth-child(1) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination1-on--m.png");}
            &:nth-child(2) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination2-on--m.png");}
            &:nth-child(3) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination3-on--m.png");}
            &:nth-child(4) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/dominusOcto/dominus_button__pagination4-on--m.png");}
            
          }
        }
      }
    }

    #section4 {
      .fp-tableCell {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
        background-position: center center;
        background-size: cover;
        resize: both;
        .layer {
          position:relative; height:100%;
          .content {
            padding-top: (65 / 1280) * 100%;
            .title {
              width:100%;
              margin:0 auto;

              img {width:100%; max-width:800px; margin-top:3.571428571428571vh;}
            }
            .body {
              width: (800 / 900) * 100vw; max-width:800px; margin:auto;

              .video-slide-box {

                .slick-list {
                  padding-top:(196 / 900) * 100vw;

                  .videoSlideTray {
                    margin-bottom:0;
                    
                    h3 {display:none;}

                    .video_slide {
                      width:(900 / 900) * 100vw; position:relative;
            
                      > div {
                        margin:0 (30 / 900) * 100vw (30 / 900) * 100vw 0; width:(381 / 900) * 100vw!important; box-sizing: border-box; float:left;
                          img {width:100%;}
                      }
                    }
                  }
                }

                .slick-dots{
                  position: absolute; top: 0; display: block; width: 100%; padding: 0; margin: 0; list-style: none; text-align: center;
                
                  li{
                    position: relative; display: inline-block; width: (133 / 900) * 100vw; height: (149 / 900) * 100vw; margin: 0 (40 / 900) * 100vw;; padding: 0;
                  
                    button{ font-size: 0; line-height: 0; display: block; width: 100%; height: 100%;padding:0; cursor: pointer; border: 0; outline: none;
                    background-repeat: no-repeat; background-size: 100% 100%; background-position: center center;}
      
                    &:nth-child(1) button {background-image: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/media/media_button__pagination1-off.png);}
                    &:nth-child(2) button {background-image: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/media/media_button__pagination2-off.png);}
                    &:nth-child(1).slick-active button{background-image: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/media/media_button__pagination1-on.png);}
                    &:nth-child(2).slick-active button{background-image: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/mobile/media/media_button__pagination2-on.png);}
                  }
                }
              }
            }
          }
        }
      }
    }

    #section5 {
      .fp-tableCell {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
        background-position: center center;
        background-size: cover;
        resize: both;
        .layer {
          position:relative; height:100%;
          .content {
            padding-top: (65 / 1280) * 100%;
            .title {
              width:(800 / 900) * 100vw;;

              img {width: (800 / 900) * 100%; max-width:800px; margin-top:3.571428571428571vh;}
            }
            .body {
              width: (800 / 900) * 100vw; max-width:800px; margin:auto;

              .community-link {
                ul {
                  width:(776 / 900) * 100vw;
                  li {
                    float:left; width: 33.3%; box-sizing: border-box; padding-right:(8/900)*100vw;
                  }
                }
              }
            }
          }
        }
      }
      #footer {
        position:absolute; padding:0; bottom:(50 / 1280) * 100vh; left:(66/900) * 100vw; z-index: 50;
        .footer_inner {
          > img {width:(800/900) * 100vw;}
          a {position:absolute; top:0; right:0; font-size:(24 / 900) * 100vw;}
        }
      }
    }
  }

  .fp-controlArrow {
    visibility: visible;
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 6.333333333333333vw;
    height: 6.333333333333333vw;
    background-color:transparent;
    border-style: solid;
    border-width: 0.3181818181818182vw;
    margin-top: -3.166666vw;
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
    -ms-transform: translate3d(0,0,0) rotate(45deg);
    transform: translate3d(0,0,0) rotate(45deg);
    text-indent: -9999px;
  }
  .fp-controlArrow.fp-prev {
    left: 6vw;
    border-color: transparent transparent #fff #fff;
  }
  .fp-controlArrow.fp-next {
    right: 6vw;
    border-color:  #fff #fff transparent transparent;
  }
}
@media all and (max-width:800px) and (orientation: landscape) {
  .link {position:relative; width:50%; height:3.3vw; margin-top:50px;}
  .modal-video {top:15%; left:15%; width:70%; height:70%;}
  .menu-inner {overflow-y:auto;}
  /*#section2 .body .char > img {height: auto; width: 70%;}*/
  #section3 .slide .txt .txt_inner {left: 43%;}
  #section3 .slide .txt .txt_inner .charTitle {height:7.571428571428571vh;}
  #section3 .slide .txt .txt_inner .charSubtitle {transform:translateY(80%); transition: .5s ease; transition-delay: .9s; height:3.857142857142857vh;}
  #section3 .slide .txt .txt_inner .charInfo {transform:translateY(100%); transition: .5s ease; transition-delay: 1s; height:3.285714285714286vh; margin-bottom:3.285714285714286vh;}
  #section3 .slide .txt .txt_inner .charMov {display:none;}
  #section3 .slide .txt .txt_inner .charDescription {height:14.42857142857143vh;}
  #section3 #slide2 .txt .txt_inner .charDescription {height:13.285714285714286vh;}
  #section3 .fp-slidesNav {width: calc(39% + 50px);}

  #section4 .slick-dots{display: block; width: 100%; padding: 0; margin: 0; list-style: none; text-align: center;}
  #section4 .slick-dots li{position: relative; display: inline-block; width: (14 / 900) * 100vw; height: (14 / 900) * 100vw; margin: 0px (8 / 900) * 100vw; padding: 0;}
  #section4 .slick-dots li button{ font-size: 0; line-height: 0; display: block; width: 100%; height: 100%; padding: 0; cursor: pointer; color: #6e655a;border: 0; outline: none; background: #6e655a; border-radius: 50%;}
  #section4 .slick-dots li.slick-active button{color: #fff; background: #fff;}
  
}
@media all and (max-width:400px) {

}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (orientation: portrait){
  #wrapper .movCover #mov {height:100%; width:auto; left:50%; transform:translateX(-50%);}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (orientation: landscape){
  #wrapper .movCover #mov {height:auto; width:100%; top:50%; transform:translateY(-50%);}
}

