@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC');

@import 'initialize';
@import 'mixin';
@import 'animate';


label {vertical-align: middle;}
input[type=radio],
input[type=checkbox] {visibility: hidden; width:0; height:0; opacity:0; position:absolute; top:-9999px; left:-9999px; vertical-align: middle;}

input[type=radio] ~ .checkText {font-family: 'Noto Sans TC', sans-serif; font-weight:700; font-size:(31/2560)*100vw; color:#647ccb; display:inline-block; vertical-align: middle;}
input[type=checkbox] ~ .checkText {font-family: 'Noto Sans TC', sans-serif; font-weight:400; font-size:(18/2560)*100vw; line-height:(22/18)*100%; color:#647ccb; display:inline-block; vertical-align: middle;}
input[type=radio] + .checkIcon,
input[type=checkbox] + .checkIcon {width:(18 /2560)*100vw; height:(18 /2560)*100vw; display:inline-block; border:(5/2560)*100vw solid #6f86cf; border-radius: (5/2560)*100vw; background-color:#f1efff; position:relative; vertical-align: middle;}
// input[type=radio].checkIcon:after {}
input[type=radio]:checked ~ .checkText {color:#7a49df;}
input[type=radio]:checked + .checkIcon,
input[type=checkbox]:checked + .checkIcon {width:(16 /2560)*100vw; height:(16 /2560)*100vw; border:(6 /2560)*100vw solid #c9afff;}
/* background-color:#7a49df; */
input[type=radio]:checked + .checkIcon:after,
input[type=checkbox]:checked + .checkIcon:after {
	content:''; position:absolute; width:(18 /2560)*100vw; height:(18 /2560)*100vw; display:block; background-color:#7a49df; top:50%; left:50%; -webkit-transform:translate(-50%, -50%); -ms-transform:translate(-50%, -50%); transform:translate(-50%, -50%); border-radius: (3 /2560)*100vw;
	-webkit-animation: scaleUp .15s cubic-bezier(0.19, 1, 0.22, 1) forwards;
	animation: scaleUp .15s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

input:not([type=radio]),
input:not([type=checkbox]) {padding:0 10px; width:100%; outline: none; -webkit-box-sizing:border-box; box-sizing:border-box;}


::-webkit-input-placeholder {color:#8782cc; font-size:(24/2560)*100vw; font-weight:400; text-align: center;}
:-ms-input-placeholder {color:#8782cc; font-size:(24/2560)*100vw; font-weight:400; text-align: center;}
::-ms-input-placeholder {color:#8782cc; font-size:(24/2560)*100vw; font-weight:400; text-align: center;}
::placeholder {color:#8782cc; font-size:(24/2560)*100vw; font-weight:400; text-align: center;}


#wrapper {
	.menu-trigger {
		top: 0;
		left: 0;
		position: fixed;
		cursor: pointer;
		z-index: 99;
		width:0; height:0;
		border:(65 / 2560) * 100vw solid #413c86;
		border-right-color:transparent!important;
		border-bottom-color:transparent!important;
		-webkit-transform:translateX(0);
		-ms-transform:translateX(0);
		transform:translateX(0);
		-webkit-transition:-webkit-transform 0.2s ease-out;
		transition:-webkit-transform 0.2s ease-out;
		transition:transform 0.2s ease-out;
		transition:transform 0.2s ease-out, -webkit-transform 0.2s ease-out;

		&.active {
			-webkit-transform:translateX(-100%);
			-ms-transform:translateX(-100%);
			transform:translateX(-100%);
		}

		a {
			position:absolute;
			display: block;
			height: 2px;
			width: (46 / 2560) * 100vw;
			background-color:#bab6e9;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			top:(-27 / 2560) * 100vw;;
			left:(-50 / 2560) * 100vw;

			&:before,
			&:after {
				content:'';
				position:absolute;
				display: block;
				height: 2px;
				width:(46 / 2560) * 100vw;
				background-color:#bab6e9;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				top:(-8 / 2560) * 100vw;
			}
			&:after {top:(8 / 2560) * 100vw;}
		}
	}


	#header {
		z-index: 1000;

		.menu-wrapper {
			height: 100%;
			width:300px;
			left: 0;
			-webkit-transform:translateX(-100%);
			-ms-transform:translateX(-100%);
			transform:translateX(-100%);
			position: fixed;
			overflow: hidden;
			-webkit-transition: all .2s ease;
			transition: all .2s ease;
			-webkit-transition-delay: 0.1s;
			transition-delay: 0.1s;
			z-index: 90;

			&.on-top {
				z-index: 100;
				-webkit-transform:translateX(0);
				-ms-transform:translateX(0);
				transform:translateX(0);
			}

			.menu-inner {
				background-color: rgba(33, 33, 44, 0.9);
				height: 100%;
				left: 0;
				width: 100%;
				padding: 21%;
				padding-top: calc(21% + 20px);
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-transition: all .2s ease;
				transition: all .2s ease;
				position: absolute;

				.menu{
					width:55.8%; margin:0 auto;
				}
				.gotop {
					width:(80 / 2560) * 100vw; margin:0 auto;
				}

				.menu {margin:100px auto;

					li {
						margin: 45px 0;
						text-align: center;

						a {
							position:relative;
							display: block;
							height:100%;

							&:after {
								content:''; background-size:100%; background-position: 0 0; background-repeat: no-repeat; -webkit-transition: 200ms ease; transition: 200ms ease; position:absolute; width:100%; height:100%; top:0; left:0; -webkit-transform: translateY(0%); -ms-transform: translateY(0%); transform: translateY(0%); opacity: 0;
							}
						}

						&.active a:after {opacity: 1;}

						&[data-menuanchor="reservation"].active a:after {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/gnb_button_list1-on.png");}
						&[data-menuanchor="newStory"].active a:after {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/gnb_button_list1-on.png");}
						&[data-menuanchor="newCharacter"].active a:after {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/gnb_button_list1-on.png");}
					}
				}

				.link {
					position:absolute;
					bottom: 1.6875vw;
					left:0; right:0;
					width:63%; margin:0 auto;
					height:24px;

					&:after {content:''; display:block; clear:both;}

					li {
						float:left;
						height:100%;
						//width:25%;
						width:33.3%;
						text-align:center;

						&:nth-child(2) {display:none;}
						&:last-child {
							float:right;
						}

						a {
							display:inline-block;
							position:relative;
							overflow:hidden;
							height:100%;

							img {height:100%;}
						}
					}
				}
			}
		}
	}
	.scroll-icon {
		position:absolute; padding:0; width:100%; bottom:1.5875vw; z-index: 50; padding: 0 10px;
		p {
			font-size:0; color:transparent; height:12px; width:54px; text-align:center; text-indent:-9999px; background:url(https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/allSection_text__scroll.png) 0 0 no-repeat; background-size:100% 100%; z-index: 3; margin:0 auto;
		}
		i {
			display:block;
			width:2px;
			height:2.5vh;
			margin:0.8298755186721992vh auto;
			position: relative;
			overflow: hidden;
			background-color: #666692;

			&:before {
				content:'';
				display:block;
				position:absolute;
				top: -2.5vh;
				height:2.5vh;
				width:2px;
				background-color:#fff;
				-webkit-animation: nextScrollAni 3s 2s linear infinite;
				animation: nextScrollAni 3s 2s linear infinite;
			}
		}
	}

	.movCover {
		position:absolute; right:0; bottom:0; top:0; left:0; width:100%; height:100%;

		&:after {content:''; display:block; position:absolute; top:0; left:0; right:0; bottom:0;
			background-image: url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/mov_cover.png");
			background-position: center center;
			background-size: cover;
			resize: both;
			background-attachment: fixed;
			z-index:1;}

		#mov{
			position: absolute;
			right: 0;
			bottom: 0;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background-size: 100% 100%;
			background-color: black; /* in case the video doesn't fit the whole page*/
			background-position: center center;
			background-size: contain;
			-o-object-fit: cover;
			object-fit: cover; /*cover video background */
			z-index:0;
		}
	}

	#fullpage {z-index:10;
		.section{
			text-align:center;
			overflow: hidden;
		}
	}
}



#main {
	.layer {
		height:100%; position:relative;

		.layer-inner {
			-webkit-transform:translateY(33.5%);
			-ms-transform:translateY(33.5%);
			transform:translateY(33.5%); height:100%;

			.modal-vid {
				margin:0 auto; width:(82 / 2560) * 100vw; height:(82 / 2560) * 100vw; background-color:rgba(0, 0, 0, 0.6); -webkit-transform:rotate(45deg); -ms-transform:rotate(45deg); transform:rotate(45deg); border:1px solid #7876c1; position:relative; cursor: pointer;

				.modal-vid-inner {
					margin:auto; width:(54 / 2560) * 100vw; height:(54 / 2560) * 100vw; background-color:transparent; border:1px solid #7876c1; position:absolute; z-index: 2; top:0; bottom:0; left:0; right:0; -webkit-transform:rotate(180deg); -ms-transform:rotate(180deg); transform:rotate(180deg);

					button {position:absolute; z-index:3; top:0; bottom:0; left:0; right:0; margin:auto; -webkit-transform:rotate(135deg); -ms-transform:rotate(135deg); transform:rotate(135deg); width:(37 / 2560) * 100vw; height:(37 / 2560) * 100vw; background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/mainSection_button__playvideo-out.png"); background-position: center; background-repeat:no-repeat; background-size: 100%;}
					&:hover button {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/mainSection_button__playvideo-over.png");}
				}


			}
			.modal-vid:before,
			.modal-vid-inner:before {content:''; display:block; position:absolute; top:-1px; left:-1px; width:0; height:0; z-index: -1;}
			.modal-vid:after,
			.modal-vid-inner:after {content:''; display:block; position:absolute; bottom:-1px; right:-1px; width:0; height:0; z-index: -1;}
			.modal-vid:hover:before,
			.modal-vid:hover:after,
			.modal-vid:hover .modal-vid-inner:before,
			.modal-vid:hover .modal-vid-inner:after {
				-webkit-animation: snake .2s linear 1 forwards;
				animation: snake .2s linear 1 forwards;
			}
			.modal-vid:hover:after,
			.modal-vid:hover .modal-vid-inner:after {
				-webkit-transform:rotate(180deg);
				-ms-transform:rotate(180deg);
				transform:rotate(180deg);
				-webkit-animation-delay: .2s;
				animation-delay: .2s;
			}

			.content {
				position:relative; width: 37.5vw; max-width:960px; margin: 0 auto;

				h1 {
					position:relative;
					margin:-1.9vh auto 0;
					-webkit-transform:translateX(-2%);
					-ms-transform:translateX(-2%);
					transform:translateX(-2%);
					width:(790/960)*100%;
				}
				h2 {
					margin-top:-1.9vh;

				}

				.store_link {
					-webkit-transform:translateY(50%);
					-ms-transform:translateY(50%);
					transform:translateY(50%); text-align:center;

					li {
						display:inline-block; margin:0 9px; width:31%; max-width: 282px;

						a {
							display:block; vertical-align:middle; width:100%; position:relative; -webkit-transition: all 0.2s ease; transition: all 0.2s ease; -webkit-transform-origin: center; -ms-transform-origin: center; transform-origin: center; background-color:rgba(72, 67, 153, 0.8); font-size:0; line-height: 0;

							&:after {content:''; display:block; position:absolute; top:-2px; left:-2px; width:calc(100% + 2px); height:calc(100% + 2px); z-index: -1; border:1px solid #6658bc; -webkit-transition:all 0.2s ease; transition:all 0.2s ease; opacity:1;}
							&:hover {-webkit-transform:scale(1.05);-ms-transform:scale(1.05);transform:scale(1.05);
								&:after {opacity:0;}
							}

							img {width:100%;}
						}
					}
				}

			}
		}

		#newsfeed {
			position:absolute; bottom:0; right:50%; margin-right:-18.75vw; background: #2d4fa3; width:298px; height:478px; -webkit-transform: translateX(100%) translateY(478px); -ms-transform: translateX(100%) translateY(478px); transform: translateX(100%) translateY(478px);-webkit-transition: -webkit-transform 0.3s ease-out;transition: -webkit-transform 0.3s ease-out;transition: transform 0.3s ease-out;transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; z-index: 51;

			&.opened {-webkit-transform:translateX(100%) translateY(0);-ms-transform:translateX(100%) translateY(0);transform:translateX(100%) translateY(0);}

			.fb-page {position: absolute; width: 282px;  height: 462px;  bottom: 8px;  left: 0;  right: 0;  margin: auto;}
			.nf-mia {position:absolute; width:100%; height:163px; top:-163px; right:0; z-index: 5;
				img {display:block; width:167px; height:163px; margin-left:auto; margin-right:10px; cursor:pointer;}
			}
			.nf-tooltips {position:absolute; top: -60px; left: 0; -webkit-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; -webkit-animation: bounce .8s infinite alternate; animation: bounce .8s infinite alternate;}
			.nf-close {position:absolute; top: -44px; right: 0; -webkit-transform-origin: 50% 50%; -ms-transform-origin: 50% 50%; transform-origin: 50% 50%; -webkit-transition: .5s ease; transition: .5s ease; cursor: pointer; z-index: 100;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);}

			&.opened .nf-close {-webkit-transform:rotate(0);-ms-transform:rotate(0);transform:rotate(0);}

			.nf-wobble {position:absolute; cursor:pointer; display:block; width:118px; height:108px; background:url('https://ic-common.pmang.cloud/static/bdt/tw/site/2018/0910/img/aside_newsfeed__like.png') 0 0 no-repeat; background-size:100% 100%; top: -36px; left:85px; z-index: 5;
				-webkit-animation: wobble 3s 1s infinite;
				animation: wobble 3s 1s infinite;}
		}

	}
}

#section1 {
	.fp-tableCell {
		background:url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/registration/reservation_background.png); background-size:100% 100%;

		.layer {
			position:relative; height:100%;

			.title {
				position:absolute; top:0; left:50%; width:2560px; background-size:100% 100%; background-position:center top; background-repeat:no-repeat; -webkit-transform:translate(-50%, -100%); -ms-transform:translate(-50%, -100%); transform:translate(-50%, -100%);
				background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/registration/reservation_curtain.png"); height:72.85714285714286vh; width:100%;

				img {width:37.5vw; max-width:960px; margin-top:3.571428571428571vh;}
			}
			.body {
				position:absolute; top:19.5%; bottom:0; width:37.5vw; max-width:960px; left:0; right:0; margin:auto;

				.reward {
					margin-bottom:1.142857142857143vh;

					ul {
						margin-top:-2.642857142857143vh;
						&:after {content:''; display:block; clear:both;}
						// li {float:left; width:33.33%;}
						li {width:100%;}
					}
				}
				.reserve_form {
					margin-bottom:12px;

					.step1 {
						background:#f1efff;

						.selectCountry {
							width:100%; padding:(28/2560)*100vh (93/2560)*100vw; border-bottom:1px solid #a99bff;
							-webkit-box-sizing: border-box;
							box-sizing: border-box;
							ul {
								&:after {content:''; display:block; clear:both;}
								li {float:left; width:33.33%; -webkit-box-sizing:border-box; box-sizing:border-box; text-align:center;}
							}
						}
						.selectTel {
							width:100%; padding:0 (93/2560)*100vw; -webkit-box-sizing: border-box; box-sizing: border-box;

							input {background:transparent; font-size:(24/2560)*100vw; color:#8782cc; font-weight:700; height:(65/1400)*100vh; line-height:(65/1400)*100vh; text-align: center;}
						}
					}
					.step2 {
						background:#37355b; padding:(10 / 1400) * 100vw 0; text-align:center; -webkit-box-sizing: border-box; box-sizing: border-box;

						span {vertical-align: middle;}
						button {position:relative; vertical-align:middle; margin-left:(35/2560)*100vw; width:(120/2560)*100vw;
							img {vertical-align: middle;}
						}
					}
					.step3 {
						.reserveBtn {width:100%; position:relative;}
					}
				}
			}
			.warning {text-align: left; height:(60 / 1400) * 100vh;}
			.warning img {height:100%;}
		}
	}

	&.active {
		.fp-tableCell {
			.layer {
				.title {-webkit-transition:700ms cubic-bezier(0.895, 0.03, 0.685, 0.22);transition:700ms cubic-bezier(0.895, 0.03, 0.685, 0.22); -webkit-transform:translate(-50%, 0); -ms-transform:translate(-50%, 0); transform:translate(-50%, 0); -webkit-transition-delay: 100ms; transition-delay: 100ms;}
			}
		}
	}
}

#section2 {
	.slide {
		.bg {position:absolute; width:100vw; height:100vh; top:0; left:0; z-index:-1; background-position: center center; background-size: cover; resize: both;

			&:after {
				content:'';
				position: absolute;
				top: 0;
				left: 50%;
				-webkit-transform: translate(-50%,-100%);
				-ms-transform: translate(-50%,-100%);
				transform: translate(-50%,-100%);
				background-image: url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_curtain.png);
				background-size:100% 100%;
				width:(1548 / 2560) * 100%;
				height:(453 / 1400) * 100%;
			}
		}
		.layer {
			position:relative; height:100%;
			.obj {
				position:absolute; opacity: 0;

				img {width:100%;}
			}

			.body {
				position:absolute; top:0; bottom:0; width:(1080 / 2560) * 100vw; max-width:1080px; left:0; right:0; margin:auto;

				.txt {z-index:5; position:absolute; width:100%; height:100%; top:0;left:0; width:100%;
					p {
						position:absolute; left:0; opacity: 0;

						img {width:100%;}

						&.title {top:(945/1400)*100%;}
						&.subtitle {top:(1025/1400)*100%;}
						&.description {top:(1073/1400)*100%;}

					}
				}
			}
		}

		&#slide2-1 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page1_background.jpg");}
			.obj {
				&.obj1 {
					width:(1912 / 2560) * 100vw;
					height:auto;
					top:0;
					left:0;
					-webkit-transform:translateY(-3%);
					-ms-transform:translateY(-3%);
					transform:translateY(-3%);
					z-index: 5;
				}
				&.obj2 {
					width: (507 / 2560) * 100vw;
					top: (453 / 2560) * 100vw;
					left: (517 / 2560) * 100vw;
					-webkit-transform:translate(-15%, 12%);
					-ms-transform:translate(-15%, 12%);
					transform:translate(-15%, 12%);
					z-index: 2;
				}
				&.obj3 {
					width: (783 / 2560) * 100vw;
					top: (378 / 2560) * 100vw;
					left: (1355 / 2560) * 100vw;
					-webkit-transform:translate(-15%, 8%);
					-ms-transform:translate(-15%, 8%);
					transform:translate(-15%, 8%);
					z-index: 3;
				}
				&.obj4 {
					width: (742 / 2560) * 100vw;
					top: (448 / 2560) * 100vw;
					left: (128 / 2560) * 100vw;
					-webkit-transform:translate(5%, 10%);
					-ms-transform:translate(5%, 10%);
					transform:translate(5%, 10%);
					z-index: 4;
				}
				&.obj5 {
					width: (479 / 2560) * 100vw;
					top: (328 / 2560) * 100vw;
					left: (1761 / 2560) * 100vw;
					-webkit-transform:translate(-15%, 3%);
					-ms-transform:translate(-15%, 3%);
					transform:translate(-15%, 3%);
					z-index: 1;
				}
			}
		}
		&#slide2-2 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page2_background.jpg");}
			.obj {
				&.obj1 {
					width:(966 / 2560) * 100vw;
					height:auto;
					top: (328 / 2560) * 100vw;
					left: (1201 / 2560) * 100vw;
					-webkit-transform:translateX(-3%);
					-ms-transform:translateX(-3%);
					transform:translateX(-3%);
					z-index: 1;
				}
				&.obj2 {
					width: (713 / 2560) * 100vw;
					top: (72 / 2560) * 100vw;
					left: (630 / 2560) * 100vw;
					-webkit-transform:translate(-8%, 5%);
					-ms-transform:translate(-8%, 5%);
					transform:translate(-8%, 5%);
					z-index: 2;
				}
				&.obj3 {
					width: (1197 / 2560) * 100vw;
					top: (167 / 2560) * 100vw;
					left: (609 / 2560) * 100vw;
					-webkit-transform:translateY(8%);
					-ms-transform:translateY(8%);
					transform:translateY(8%);
					z-index: 3;
				}
			}
		}
		&#slide2-3 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page2_background.jpg");}
			.obj {
				&.obj1 {
					width:(1501 / 2560) * 100vw;
					top: 0;
					left: (567 / 2560) * 100vw;
					-webkit-transform:translateY(0);
					-ms-transform:translateY(0);
					transform:translateY(0);
					animation:bounce 3s infinite alternate-reverse;
					z-index: 5;
				}
				&.obj2 {
					width: (885 / 2560) * 100vw;
					top: 0;
					left: (1079 / 2560) * 100vw;
					animation:glow 6s infinite alternate-reverse;
					z-index: 2;
				}
			}
		}
		&#slide2-4 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_page2_background.jpg");}
			.char {
				position:absolute; top:0; bottom:0; left:0; right:0; margin:auto;

				> img {width:100%; height:auto;position:absolute; top:0; bottom:0; left:0; right:0; margin:auto;}

				&.char1 {width:90%;height:90%; -webkit-transform:scale(1.2); -ms-transform:scale(1.2); transform:scale(1.2); -webkit-transition:-webkit-transform 200ms ease; transition:-webkit-transform 200ms ease; transition:transform 200ms ease; transition:transform 200ms ease, -webkit-transform 200ms ease; -webkit-transition-delay:420ms; transition-delay:420ms; z-index: 5; -webkit-transform-origin: 50% 50%; -ms-transform-origin: 50% 50%; transform-origin: 50% 50%;}
				&.char2 {width: 87%;height:90%; -webkit-transform:translateX(0); -ms-transform:translateX(0); transform:translateX(0); -webkit-transition:-webkit-transform 150ms ease-out; transition:-webkit-transform 150ms ease-out; transition:transform 150ms ease-out; transition:transform 150ms ease-out, -webkit-transform 150ms ease-out; -webkit-transition-delay:550ms; transition-delay:550ms;}
				&.char3 {width: 61.020833%;height:90%;-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0); -webkit-transition:-webkit-transform 150ms ease-out; transition:-webkit-transform 150ms ease-out; transition:transform 150ms ease-out; transition:transform 150ms ease-out, -webkit-transform 150ms ease-out; -webkit-transition-delay:550ms; transition-delay:550ms;}
			}
			.txt {
				p {
					&.script {top:(754/1400)*100%;}
				}
			}
			&.active {
				.fp-tableCell {
					.layer {
						.body {
							.char1 {-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);}
							.char2 {-webkit-transform: translateX(-30.965553%);-ms-transform: translateX(-30.965553%);transform: translateX(-30.965553%);}
							.char3 {-webkit-transform:translateX(62.599104%);-ms-transform:translateX(62.599104%);transform:translateX(62.599104%);}
						}
					}
				}
			}
		}
	}
	&.active {
		.fp-slide.active {
			.fp-tableCell {
				.bg:after {
					-webkit-transition: .7s cubic-bezier(.895,.03,.685,.22);
					transition: .7s cubic-bezier(.895,.03,.685,.22);
					-webkit-transform: translate(-50%,0);
					-ms-transform: translate(-50%,0);
					transform: translate(-50%,0);
					-webkit-transition-delay: .1s;
					transition-delay: .1s;
				}
				.layer {
					.obj {
						opacity:1!important; -webkit-transform:translate(0, 0)!important; -ms-transform:translate(0, 0)!important; transform:translate(0, 0)!important; -webkit-transition: all 3s !important; transition: all 3s !important;

						&.obj2 {-webkit-transition-delay: 0.3s!important;transition-delay: 0.3s!important}
						&.obj3 {-webkit-transition-delay: 0.5s!important;transition-delay: 0.5s!important}
						&.obj4 {-webkit-transition-delay: 0.8s!important;transition-delay: 0.8s!important}
						&.obj5 {-webkit-transition-delay: 1.2s!important;transition-delay: 1.2s!important}
					}
					.content {
						.txt {
							p { -webkit-animation: fadeInUp .6s linear forwards; animation: fadeInUp .6s linear forwards;

								&.subtitle {-webkit-animation-delay: .5s;animation-delay: .5s;}
								&.description {-webkit-animation-delay: .5s;animation-delay: .5s;}
							}
						}
					}
				}
			}
		}
	}

	.fp-slidesNav {
		width: 100%; text-align: center; top:(44 / 1400) * 100vh;

		ul {
			li {
				margin: 0 (7/2560)*100vw; width:(103 / 2560) * 100vw; height:(116 / 2560) * 100vw;

				a {
					width:100%; height:100%; background-size: 100% 100%; background-repeat: no-repeat; background-position: 0 0;

					span {display:none;}
				}
				&:nth-child(1) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination1-off--d.png");}
				&:nth-child(2) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination2-off--d.png");}
				&:nth-child(3) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination3-off--d.png");}
				&:nth-child(4) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination4-off--d.png");}
				&:nth-child(1) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination1-on--d.png");}
				&:nth-child(2) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination2-on--d.png");}
				&:nth-child(3) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination3-on--d.png");}
				&:nth-child(4) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/story/story_button__pagination4-on--d.png");}

			}
		}
	}

	.fp-controlArrow {visibility: hidden;}


}


#section3 {
	.fp-slidesNav {
		top:(120 / 1400) * 100vh; right:50%; width: (530 / 2560) * 100vw; -webkit-box-sizing:border-box; box-sizing:border-box; text-align:left;

		ul {

			li {
				margin:0; margin-right: (10/2560)*100vw; width:(74 / 2560) * 100vw; height:(83 / 2560) * 100vw;

				a {
					width:100%; height:100%; background-size: 100% 100%; background-repeat: no-repeat; background-position: 0 0;

					span {display:none;}
				}
				&:nth-child(1) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination1-off--d.png");}
				&:nth-child(2) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination2-off--d.png");}
				&:nth-child(3) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination3-off--d.png");}
				&:nth-child(4) a {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination4-off--d.png");}
				&:nth-child(1) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination1-on--d.png");}
				&:nth-child(2) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination2-on--d.png");}
				&:nth-child(3) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination3-on--d.png");}
				&:nth-child(4) a.active {background-image:url("https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/dominusOcto/dominus_button__pagination4-on--d.png");}

			}
		}
	}
	.fp-controlArrow {visibility: hidden;}

	.slide {
		position:relative;

		.bg {position:absolute; width:100vw; height:100vh; top:0; left:0; z-index:-1; background-position: center center; background-size: cover; resize: both;}


		.txt {
			position:absolute; z-index:2; top:0; left:50%; opacity:0; -webkit-transform:translateX(-115%); -ms-transform:translateX(-115%); transform:translateX(-115%); -webkit-transform-origin: left top; -ms-transform-origin: left top; transform-origin: left top; height:100%; width:calc(50% + 50px); background:#eeeeee url('https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/newChar_txtbg.jpg') right center no-repeat; background-size:100% 100%; -webkit-transition: .2s ease-in; transition: .2s ease-in;

			.txt_inner {
				position:absolute; top:(248 / 1400) * 100vh; right:0; width: (530 / 2560) * 100vw; -webkit-box-sizing:border-box; box-sizing:border-box; text-align:left;

				div {
					opacity:0;

					img {height:100%;}
				}

				.charTitle {height:(92 / 1400) * 100vh;}
				.charSubtitle {-webkit-transform:translateY(80%);-ms-transform:translateY(80%);transform:translateY(80%); -webkit-transition: .5s ease; transition: .5s ease; -webkit-transition-delay: .9s; transition-delay: .9s; height:(40 / 1400) * 100vh;}
				.charInfo {-webkit-transform:translateY(100%);-ms-transform:translateY(100%);transform:translateY(100%); -webkit-transition: .5s ease; transition: .5s ease; -webkit-transition-delay: 1s; transition-delay: 1s; height:(32 / 1400) * 100vh; margin-bottom:(30 / 1400) * 100vh;}
				.charDescription {height:(300 / 1400) * 100vh;}
				.charMov {height:(210 / 1400) * 100vh}
			}
		}

		.img {
			position:absolute; z-index:5; top:0; right:50%; -webkit-transform: translateX(84%); -ms-transform: translateX(84%); transform: translateX(84%); opacity:0; -webkit-transform-origin: left top; -ms-transform-origin: left top; transform-origin: left top; height:100%; width:50%;
			pointer-events:none;

			img {width:83%; position:absolute; top:0; bottom:0; left:0; right:0; margin:auto;}
		}
		&#slide1 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/newChar1_bg.jpg");}
			.img img {-webkit-transform:translate(0,-5.794398%);-ms-transform:translate(0,-5.794398%);transform:translate(0,-5.794398%);}
		}
		&#slide2 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/newChar2_bg.jpg");}
			.img img {-webkit-transform:translate(0,3.381642512077295%);-ms-transform:translate(0,3.381642512077295%);transform:translate(0,3.381642512077295%);}
		}
		&#slide3 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/newChar3_bg.jpg");}
			.img img {-webkit-transform:translate(0,-3.458937%);-ms-transform:translate(0,-3.458937%);transform:translate(0,-3.458937%);}
		}
		&#slide4 {
			.bg {background-image:url("https://ic-common.pmang.cloud/static/bdt/ko/site/2018/0928/img/newChar4_bg.jpg");}
			.img img {-webkit-transform:translate(0,-2.941476%);-ms-transform:translate(0,-2.941476%);transform:translate(0,-2.941476%);}
		}
	}
	&.active {
		.slide.active {
			.txt {
				-webkit-transform:translateX(-100%);
				-ms-transform:translateX(-100%);
				transform:translateX(-100%); opacity:1; -webkit-transition: .5s ease-out; transition: .5s ease-out;

				.txt_inner {
					.charTitle {opacity:1; -webkit-transition:opacity 1s ease; transition:opacity 1s ease; -webkit-transition-delay: .7s; transition-delay: .7s;}
					.charSubtitle {opacity: 1; -webkit-transform:translateY(0); -ms-transform:translateY(0); transform:translateY(0);}
					.charInfo {opacity: 1; -webkit-transform:translateY(0); -ms-transform:translateY(0); transform:translateY(0);}
					.charDescription {opacity:1; -webkit-transition:opacity 1s ease; transition:opacity 1s ease; -webkit-transition-delay: 1.25s; transition-delay: 1.25s;}
					.charDescription_m {display:none;}
					.charMov {opacity: 1; -webkit-transition:opacity 1s ease; transition:opacity 1s ease; -webkit-transition-delay: 1.4s; transition-delay: 1.4s; cursor: pointer;}
				}
			}
			.img { opacity:1; -webkit-transform: translateX(88%); -ms-transform: translateX(88%); transform: translateX(88%); -webkit-transition: 1s ease-out; transition: 1s ease-out; -webkit-transition-delay:.8s; transition-delay:.8s;}
		}
	}
}

#section4 {
	.fp-tableCell {
		background:url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/media/media_background.jpg) 0 0 no-repeat; background-size:100% 100%;
	}
	.layer {
		height:100%;

		.content {padding-top: 80px; -webkit-box-sizing:border-box; box-sizing:border-box; padding-bottom:0;
			.title {margin:0 auto (78 / 1400) * 100vh; width:(470 / 2560) * 100vw}
		}

		.slick-list {height:100%;}
		.body {
			width:37.5vw; max-width:960px; margin:auto;

			.videoSlideTray {
				outline:none;  margin-bottom:(44 / 1400) * 100vh;

				.video_slide {
					width:100%; position:relative;

					.slick-dotted.slick-slider{margin-bottom: 30px;}

					> div {
						margin-right:(12 / 2560) * 100vw; border:2px solid #000; width:(179 / 2560) * 100vw!important; -webkit-box-sizing: border-box; box-sizing: border-box; float:left;
						img {width:100%;}
					}

					.slick-dots{
						position: absolute; bottom: -3vh; display: block; width: 100%; padding: 0; margin: 0; list-style: none; text-align: center;

						li{
							position: relative; display: inline-block; width: 20px; height: 20px; margin: 0px 0.9vw;	padding: 0; cursor: pointer;

							button{ font-size: 0; line-height: 0; display: block; width: 20px; height: 20px; padding: 5px; cursor: pointer; color: #6e655a;border: 0; outline: none; background: #6e655a; border-radius: 50%;}

							&.slick-active button{color: #fff; background: #fff;}
						}
					}
				}
			}
		}
	}
}


#section5 {
	.fp-tableCell {
		background:url(https://ic-common.pmang.cloud/static/bdt/tw/site/2019/0213/img/desktop/community/community_background.jpg) 0 0 no-repeat; background-size:100% 100%;
	}
	.layer {
		height:100%;

		.content {padding-top: 80px; -webkit-box-sizing:border-box; box-sizing:border-box; padding-bottom:0;
			.title {margin:0 auto (110 / 1400) * 100vh; width:(519 / 2560) * 100vw}
		}

		.body {
			width: (960 / 2560) * 100vw; max-width: 960px; margin:0 auto; overflow:hidden;
			.community-link {
				ul {
					width:(988 / 2560) * 100vw;
					li {
						//float:left; width: 25%; -webkit-box-sizing: border-box; box-sizing: border-box; padding-right:(28/2560)*100vw;
						float:left; width: 33.3%; -webkit-box-sizing: border-box; box-sizing: border-box; padding-right:(28/2560)*100vw;

						&:nth-child(2) {display:none;}
					}
				}
			}
		}
	}

	#footer {
		position:absolute; padding:0; bottom:(50 / 1400) * 100vh; left:(50/2560) * 100vw; z-index: 50;

		.footer_inner {
			> img {width:(644/2560) * 100vw;}
			a {position:absolute; top:0; right:0; font-family: 'Noto Sans TC', sans-serif;color:#4e465d; font-weight:bold; font-size:(18 / 2560) * 100vw;}
		}
	}

}


@import 'mediaquery';