
@-webkit-keyframes bounce {
  from {-webkit-transform: translateY(-1.25%) rotate(-0.25deg);}
  to {-webkit-transform: translateY(0) rotate(0.5deg) scale(0.995);}
}
@keyframes bounce {
  from {transform: translateY(-1.25%) rotate(-0.25deg);}
  to {transform: translateY(0) rotate(0.5deg) scale(0.995);}
}

@keyframes snake {
  0% {
    width:0; height:0;
  }
  35% {
    width:calc(100% + 1px); height:0; border-top:1px solid #cca2ff;
  }
  100% {
    width:calc(100% + 1px); height:calc(100% + 1px);  border-top:1px solid #cca2ff; border-right:1px solid #cca2ff;
  }
}


@keyframes snake2 {
  0% {
    width:0; height:0;
  }
  17.5% {
    width:calc(100% + 1px); height:0; border-top:1px solid #cca2ff;
  }
  50% {
    width:calc(100% + 1px); height:calc(100% + 1px);  border-top:1px solid #cca2ff; border-right:1px solid #cca2ff;
  }
  67.5% {
    width:calc(100% + 1px); height:calc(100% + 1px);  border-top:1px solid transparent; border-right:1px solid transparent;
  }
  75% {
    width:calc(100% + 1px); height:0; border-top:1px solid transparent;
  }
  100% {
    width:0; height:0;
  }
}

@-webkit-keyframes glow {
  from {
    opacity: 0; -webkit-transform:scale(1);
  }
  25% {
    opacity: 1; -webkit-transform:scale(1.4, 0.92);
  }
  40% {
    opacity: 1; -webkit-transform:scale(1.1, 0.96);
  }
  60% {
    opacity: 1; -webkit-transform:scale(1.2, 0.92);
  }
  
  to {
    opacity: 1; -webkit-transform: scale(1.4, 0.9);
  }
}
@keyframes glow {
  from {
    opacity: 0; transform:scale(1);
  }
  25% {
    opacity: 1; transform:scale(1.4, 0.92);
  }
  40% {
    opacity: 1; transform:scale(1.1, 0.96);
  }
  60% {
    opacity: 1; transform:scale(1.2, 0.92);
  }
  
  to {
    opacity: 1; transform: scale(1.4, 0.9);
  }
}

@-webkit-keyframes scaleUp {
  from {
    opacity: 0; -webkit-transform:translate(-50%,-50%) scale(0);
  }
  to {
    opacity: 1; -webkit-transform: translate(-50%,-50%) scale(1);
  }
}
@keyframes scaleUp {
  from {
    opacity: 0; transform: translate(-50%,-50%) scale(0);
  }
  to {
    opacity: 1; transform: translate(-50%,-50%) scale(1);
  }
}

@keyframes checkmark {
  0% {height: 0; width: 0; opacity: 1;}
  20% {height: 0.377778vw; width: 0; opacity: 1;}
  40% {height: 0.377778vw; width: 1.033333vw; opacity: 1;}
  100% {height: 0.377778vw; width: 1.033333vw; opacity: 1;}
}

@keyframes nextScrollAni {
  from {top: -2.5vh; height: 10%;}
  50% {height: 10%;}
  70% {height: 80%;}
  80% { top: 55%;}
  95% {top: 110%;}
  to {top: 100%;}
}

@keyframes modal-video {
  from {opacity:0}
  to{opacity:1}
}

@-webkit-keyframes modal-video-inner {
  from {-webkit-transform:translate(0, 100px)}
  to{-webkit-transform:translate(0, 0)}
}
@keyframes modal-video-inner {
  from {transform:translate(0, 100px)}
  to{transform:translate(0, 0)}
}
  
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}